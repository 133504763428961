<div class="landing_main_wraper fade-in-down" style="text-align:center">
	<!-- Logout Button -->
	 <button title="Logout" class="btn-logout logout-user" type="button" id="btn-logout" (click)="logout()">
		<span class="sr-only">Logout</span>
	 </button>
    <div class="main-container">
        <div class="logo_section">
            <img src="{{assetsPath.brandingAssetsStudent.logo}}" alt="Logo">
        </div>
        <div class="justify_space_between" *ngIf="title">
            <div class="landing_content_area">
                <div class="parentbox">
                <div class="box1">
                    <h6>Charlotte Bot</h6>
                    <h5>Goals</h5>
                    <p>The goal of this bot is to enhance Student (Charlotte) Bot with Gen AI capabilities  using selected data sources and learn following</p>
                    <ul>
                        <li>Provide accurate, complete, and simple answers to the full range of common student questions with minimal training </li>
                        <li>Provide students the information they need without having to speak to an agent, thus reducing top volume call drivers </li>
                        <li>Help SSA and CCT with information from existing training and university resources to better serve students</li>
                    </ul>
                    <h5>Nature of Information</h5>
                    <div disabled>
						<div class="text-content">
						The responses generated by this chatbot using generative AI are based on vast amounts of data and algorithms, but they may not always be correct, complete, or up-to-date. Users should treat the information as a starting point and verify from trusted sources before making decisions based on it.  For more information concerning the use of this chatbot please click the link to Adtalem’s Chatbot Use Acknowledgement Document.
							</div>
					</div>
                    <div [class.isDisabled]="disabledButton" [ngClass]="[buttonArea]"  (click)="readAcknowledgment()"><div class="child1" >Please read and acknowledge document</div><div *ngIf="toshowTick" class="child2"><div  id="checkmark"></div></div></div>
                    <div>
                        <div *ngIf ="showAcknowledge" class="acknowledge_container">
                            <div class="acknowledge_heading">
                                Acknowledge document
                                <span (click)="closeButton()" class="close"></span>
                            </div>
                            <div class="acknowledge_content_window">
                                <div class="acknowledge_content">
                                    <div class="acknowledge_content_style">
                                    <br>
                                    <br>
                                    <br>
                                    <p>Acknowledge document</p> 
                                    <ol>
                                        <li>Nature of Information: The responses generated by this chatbot using generative AI 
                                            are based on vast amounts of data and algorithms, but they may not always be 
                                            correct, complete, or up-to-date. Users should treat the information as a starting 
                                            point and verify from trusted sources before making decisions based on it.</li>
                                        No Human Oversight: The responses are generated automatically, without human 
                                            oversight or intervention. As such, they may occasionally contain errors, 
                                            misinterpretations, or irrelevant information.
                                        <li> No Personal Advice: The information provided by the chatbot is general in nature 
                                            and should not be considered as personal advice. Users should consult with relevant 
                                            professionals before making decisions, especially in areas that require specialized 
                                            knowledge or expertise. </li>
                                        Data Privacy: While this chatbot is designed to prioritize user privacy, users are 
                                            advised not to share sensitive, personal, or confidential information. We do not
                                            guarantee complete confidentiality or security.
                                        <li>Limitation of Liability: The developers, operators, and affiliates of this chatbot will 
                                            not be liable for any direct, indirect, incidental, consequential, or special damages, 
                                            including but not limited to damages for loss of profits, goodwill, or data, arising out 
                                            of or in connection with the use of this chatbot. </li>
                                        <li>Not a Substitute: This chatbot is not a substitute for human expertise or judgment. 
                                            Relying solely on its responses without external validation can lead to misinformed 
                                            decisions.</li>
                                    </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="checkbox">
                                <input type="checkbox" id ="chkbox" (click)="clickAcknowledgment()" [(ngModel)]="toShow" (change)="acknowledgeTerms($event.target.checked)">&nbsp;I acknowledge and agree to the terms and conditions acknowledgment document.
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <button [disabled]="isDisabled" class="conversation_btn"  (click)="startConversation()" >Start a conversation</button>
                            </div>
                            </div>
						<!-- <textarea disabled rows="4" cols="80" class="textarea-placeholder" placeholder=" Nature of Information: The responses generated by this chatbot using generative AI are based on vast amounts of data and algorithms, but they may not always be correct, complete, or up-to-date. Users should treat the information as a starting point and verify from trusted sources before making decisions based on it.  For more information concerning the use of this chatbot please click the link to Adtalem’s Chatbot Use Acknowledgement Document."></textarea>
						 </div>
						 <p >Please click here to view<a href="https://storage.googleapis.com/public-resource-walden/AcknowledgementDocument.pdf"  target="_blank" rel="noopener noreferrer"  style="font-weight: bold;" ><u style="cursor: pointer; color: #00657c !important"> acknowledgment document </u></a></p> 
                    <div style="margin:auto"><p><input type="checkbox" [(ngModel)]="toShowGCP">&nbsp;By using this chatbot, users acknowledge and agree to the terms stated in this disclaimer.</p></div> -->
					</div>
            </div>
        </div>
    </div>  
</div>
<div *ngIf="chatIconShow" style=" z-index: 999; position: fixed; right: 20px; bottom: 10px; color: #003b4c; font-weight: bolder;">Avaamo chat Bot</div>
