
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {  HttpClientModule } from '@angular/common/http';

import { HomeComponent } from './components/home/home.component';
import { EnrollmentAgentComponent } from './components/enrollment-agent/enrollment-agent.component';
import { AvaamoComponent } from './components/student-support/avaamo/avaamo.component';
import { GcpComponent } from './components/student-support/gcp/gcp.component';
import { AzureComponent } from './components/student-support/azure/azure.component';
import { AppRoutingModule } from './app-routing.module';
import {  OKTA_CONFIG,   OktaAuthModule} from '@okta/okta-angular';

import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import oktaConfig from './app.config';
import { StudentAvaamoBotService } from './services/student-avaamo-bot.service';
import OktaAuth from '@okta/okta-auth-js';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { LogoutCallbackComponent } from './components/logout-callback/logout-callback.component';
const oktaConfigDetails = new OktaAuth(oktaConfig.oidc);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EnrollmentAgentComponent,
    LogoutCallbackComponent,
    AvaamoComponent,
    GcpComponent,
    AzureComponent,
    ChatbotComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    OktaAuthModule
  ],
  providers: [
   {provide:OKTA_CONFIG, useValue: {oktaAuth :oktaConfigDetails}},
    StudentAvaamoBotService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
