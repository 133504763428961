declare const window: any;
declare function loadChat(a: any, b: any, c: any): any;
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { IDToken, OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

import { AssetsPath } from 'src/static-assets/assets-path';
import { environment } from 'src/environments/environment';
import { StudentAvaamoBotService } from '../../../services/student-avaamo-bot.service';
import { ScriptLoaderService } from '../../../services/load-script-service.service';
@Component({
  selector: 'app-avaamo',
  templateUrl: './avaamo.component.html',
  styleUrls: ['./avaamo.component.scss']
})
export class AvaamoComponent {

    public toShowAvaamo = false;
    public toShowGCP = false;
    public showAcknowledge = false;
    public disabledButton = false;
    public toshowTick = false;
    public chatIconShow = false;
    public isDisabled = true;
    public boxArea = "box1";
    public buttonArea = "btn-area";
    public assetsPath = AssetsPath;
    public title = `Chat bots for ${environment.name} University`;
    public userData = {
      name: 'no user',
      email: 'no user'
    };
    acknowledged: boolean = false;

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
      location.reload()
    }
   
    constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth, private studentBotService: StudentAvaamoBotService,  private dynamicScriptLoader: ScriptLoaderService) {}


    ngOnInit() {
      const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken;
      sessionStorage.setItem('access_token',oktaToken.accessToken);
      this.checkAcknowledgment();
      this.userAvaamoData();
      
 // this.submitForm();
      this.userData.email = sessionStorage.getItem('staffEmail');
     // this.loadScripts();
     
      
  // load the chatbot script here
 
  // call the API which harish provided
  }

  async userAvaamoData(){
    const userDetails = await this.oktaAuth.getUser();
    sessionStorage.setItem('uid',userDetails.preferred_username)
    sessionStorage.setItem('userName',userDetails.name)
    sessionStorage.setItem('userEmail',userDetails.email)
  }
  
      // code fro encrypting the user details and sending it to avaamo bot
      // public encrypt(text, key, iv) {
      //   const crypto = window.crypto; // Support for older browsers
      //   const encoder = new TextEncoder();
      //   const data = encoder.encode(text);
    
      //   return crypto.subtle.importKey(
      //     'raw',
      //     encoder.encode(key),
      //     { name: 'AES-CBC', length: 256 },
      //     false,
      //     ['encrypt']
      //   ).then((aesKey) => {
      //     return crypto.subtle.encrypt(
      //       { name: 'AES-CBC', iv: encoder.encode(iv) },
      //       aesKey,
      //       data
      //     );
      //   }).then((encryptedData) => {
      //     return btoa(String.fromCharCode(...new Uint8Array(encryptedData)));
      //   });
      // }
    
      //code for initializing the chatbot
      // public AvaamoChatBot = function(t) {
      //   function o(t, o) {
      //       var n = document.createElement("script");
      //       n.setAttribute("src", t), 
      //       n.setAttribute("id", "avm-web-channel"), n.onload = o, document.body.appendChild(n)
      //   }
      //   return this.options = t || {}, this.load = function(t) {
      //       o(this.options.url, function() {
      //           window['Avaamo'].addFrame(), t && "function" == typeof(t) && t(window['Avaamo'])
      //       })
      //   }, this
      // };
      
    
      //method called on clicking submit button in the form
      // private submitForm() {
      //   console.log(this.userData)
      //   const aesIv = "g41bR7LJY2ID3N5b"; // Replace with your AES IV (Initialization Vector)
      //   const devKey = "g41bR7LJY2ID3N5aKlfmtzrXFwP9eDvr"; // Replace with your 32-bit AES key
      //   // Encrypt the user data
      //   this.encrypt(JSON.stringify(this.userData), devKey, aesIv).then((encryptedData) => {
      //   this.AvaamoChatBot({
      //     url: `https://c25.avaamo.com/web_channels/3e43aacb-9180-4ead-9ab3-e38717dcc16f?encUser=${encodeURIComponent(encryptedData)}`
      //   }).load();
      //   }).catch((error) => {
      //     console.error('Encryption error:', error);
      //   });
      // }

      private StudentAsStaffBotAPI() {
        this.studentBotService.getStaffAccessToken()
          .subscribe((data:any) => {
            var bot_token = data.result.token;
            // after getting data needs to called load fucntion from the chatbot script
            loadChat(environment.studentAsStaffConfig.channelId, bot_token, 'charlotte');
          })
      }

      private loadScripts() {
        // You can load multiple scripts by just providing the key as argument into load method of the service
        this.dynamicScriptLoader.load('chatbot').then(data => {
          console.log('script loaded.');
          // Script Loaded Successfully
          //this.getbotToken();
          this.StudentAsStaffBotAPI();
    
        }).catch(error => {
          console.log("Charlotte is currently unavailable",error);
        //  this.statusMessage = "Charlotte is currently unavailable";
        } );
      }

  public readAcknowledgment()
{
  this.showAcknowledge= !this.showAcknowledge;
}

public closeButton()
{
  this.showAcknowledge = !this.showAcknowledge;
}

public startConversation(){
  this.showAcknowledge= !this.showAcknowledge;
  this.boxArea = "box1 landing"
  this.buttonArea = "accepted_button";
  this.disabledButton = true;
  this.toshowTick = true;
  this.chatIconShow = true;
  this.loadScripts();
}

public clickAcknowledgment()
{
  this.isDisabled= !this.isDisabled;
}

checkAcknowledgment() {
  const acknowledgedString = localStorage.getItem('termsAcknowledged');
  if (acknowledgedString === 'true') {
    this.acknowledged = true;
    this.buttonArea = "accepted_button";
    this.toshowTick = true;
  this.chatIconShow = true;
  this.loadScripts();
  }
}

acknowledgeTerms(checked: boolean) {
  this.acknowledged = checked;
  localStorage.setItem('termsAcknowledged', checked ? 'true' : 'false');
}

async logout(){
  // sessionStorage.clear();
  // this.removeAllCookies();
  sessionStorage.setItem('lastRoute','/studentbot')
  await this.oktaAuth.signOut();
}

removeAllCookies(){
  const cookies = document.cookie.split(";");
  cookies.forEach(cookie => {
    const [name] = cookie.split("=");
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/studentbot`;
  });
}


}
  