declare function setApiUrlsForEnrollment(a: any): any;
declare function getChatbotIdForEnrollmentBot(): any;
declare function setApiUrlsForStudentStaff(a: any): any;
declare function getChatbotIdForStudentAzureBot(): any;
import { Component, Inject, OnInit } from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { AssetsPath } from 'src/static-assets/assets-path';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styles: []
})
export class AppComponent implements OnInit{
    public assetsPath = AssetsPath;
    private isAuthenticated:boolean = false;
    user: string = '';
  public title = `Chat bots for ${environment.name} University`;
  public userData = {
    name: 'no user',
    email: 'no user'
  };
  public userEmail: any;
  studentAzurePath = '/student-support-azure';
  enrollmentAzurePath ='/enrollmentcopilot';
  chatbotId = "";
  acknowledgmentVerified: string = '';

  constructor(public authStateService: OktaAuthStateService,  @Inject(OKTA_AUTH) 
      private oktaAuth: OktaAuth) {}
      

  ngOnInit() {
    const currentUrl = window.location.pathname;
    this.chatbotId = sessionStorage.getItem('chatbotIdEnr');
  setApiUrlsForEnrollment(environment.enrollmentConfig);
  setApiUrlsForStudentStaff(environment.studentAsStaffConfig);
  this.acknowledgmentVerified = sessionStorage.getItem('acknowledgementVerified');
  getChatbotIdForEnrollmentBot();
  getChatbotIdForStudentAzureBot();
  // check chatbot ID present in the session or not
  // if not, call chatbot ID API
  
 // console.log("authStateService" + this.authStateService.authState$);
  // console.log("oktaAuth - accessToken" + this.oktaAuth.getAccessToken());
  
  // const user = await this.oktaAuth.getUser();
  // console.log("user details: ",user);
  // sessionStorage.setItem('userEmail', user.email)
  // console.log("oktaAuth - refresh Token" +this.oktaAuth.getRefreshToken());
  
}


}